<template>
  <div>
    <div class="my-6 text-start">
      <h3 class="font-bold">Criar um novo lançamento</h3>
      <span class="text-sm text-gray-500">Preencha as informações abaixo e clique no botão <b>criar</b> para
        salvar as alterações.
      </span>
    </div>

    <div class="flex mb-6 justify-end">
      <router-link :to="`/financeiro/criar/${route.params.id_evento}`">
        <Button color="primary" :text="`Criar Automático`"></Button>
      </router-link>
    </div>

    <div class="card shadow-sm border p-8 bg-white">
      <div class="flex flex-col lg:flex-row justify-center">
        <div class="w-full p-2">
          <Input type="date" v-model="data.data" label="Data" />
        </div>
        <div class="w-full p-2">
          <Input name="Descrição" v-model="data.descricao" type="default" label="Descrição" placeholder="Descrição" />
        </div>
      </div>
      <div class="flex flex-col lg:flex-row justify-center">
        <div class="w-full p-2">
          <Input type="money" v-model="data.valor" label="Valor" placeholder="Valor" />
        </div>
        <div class="w-full p-2">
          <Input name="pix" type="default" v-model="data.pix" label="Pix" placeholder="Pix" :data="tipoPag" />
        </div>
        <div class="w-full p-2">
          <Input type="select" v-model="data.id_pagamento_tipo" label="Tipo de Lançamento"
            placeholder="Tipo de Lançamento" :data="tipoPag" />
        </div>
      </div>
      <div class="flex flex-col lg:flex-row justify-center mt-3">
        <button class="
              bg-primary
              rounded-md
              shadow-md
              transform
              hover:scale-105
              px-10
              py-2
              flex
              justify-center
              items-center
              font-bold
            " style="bottom: 20px; left: 20px">
          <label class="text-white" for="logo">Adicionar Comprovante</label>
          <i class="ml-3 text-white gg-file"></i>
          <input @change="changeImage" class="absolute opacity-0 bton" id="logo" type="file"
            style="bottom: -35px; left: -0px" />
        </button>

        <button v-if="data.comprovante" class="
              bg-primary
              rounded-md
              shadow-md
              transform
              hover:scale-105
              px-10
              py-2
              my-3
              flex
              justify-self-end
              justify-center
              items-center
              font-bold
            " @click="() => visualiarComprovante(data.comprovante)">
          <label class="text-white" for="logo3">Visualizar Comprovante</label>
          <i class="ml-3 text-white gg-file"></i>
        </button>
      </div>
    </div>

    <!-- Pagamento Bancario -->

    <hr class="my-5" />
    <div class="card shadow-sm border p-8 bg-white" v-if="mostraDados">
      <div class="flex">
        <div class="flex-1 mr-3">
          <Input type="default" placeholder="Banco" label="Banco" v-model="dadosBancarios.banco" />
        </div>

        <div class="flex-1 mr-3">
          <Input type="default" placeholder="Agência" label="Agência" v-model="dadosBancarios.agencia" />
        </div>

        <div class="flex-1 mr-3">
          <Input type="select" placeholder="Tipo de Conta" label="Tipo de Conta" v-model="dadosBancarios.tipo_conta"
            :data="tipoConta" />
        </div>

      </div>

      <div class="flex my-5">
        <div class="flex-1 mr-3">
          <Input type="default" placeholder="Número da Conta" label="Número da Conta"
            v-model="dadosBancarios.numero_conta" />
        </div>

        <div class="flex-1 mr-3">
          <Input type="default" placeholder="Número da Conta Digito" label="Número da Conta Digito"
            v-model="dadosBancarios.numero_conta_digito" />
        </div>

        <div class="flex-1 mr-3">
          <Input type="default" placeholder="Nome do Titular" label="Nome do Titular"
            v-model="dadosBancarios.nome_titular" />
        </div>

      </div>

      <div class="flex my-5">
        <div class="flex-1 mr-3">
          <Input type="default" placeholder="CPF_CNPJ" label="CPF-CNPJ" v-model="dadosBancarios.cpf_cnpj" />
        </div>

        <div class="flex-1 mr-3">
          <Input type="select" placeholder="Tipo de Pessoa" label="Tipo de Pessoa" :data="tipoPessoa"
            v-model="dadosBancarios.tipo_pessoa" />
        </div>
      </div>

    </div>

    <div class="flex justify-between mt-5">
      <Button @click="create" color="primary" text="Criar"></Button>
    </div>
  </div>
</template>

<script>
import Input from "../../components/Elements/Input.vue";
import Button from "../../components/Elements/Button.vue";
import { inject, ref, onMounted, watch } from "vue";
import { GET, POST } from "../../services/api";
import { isEmpty } from "../../services/validate";
import { useRouter, useRoute } from "vue-router";
import { dataAtualFormatada } from "@/services/formater";
import { useStore } from "vuex";

export default {
  name: "CriarFinanceiro",
  components: {
    Input,
    Button,
  },
  data() {
    return {
      isMobile: false,
    };
  },

  created() {
    this.isMobile = window.innerWidth < 800 ? true : false;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 800 ? true : false;
    });
  },

  setup() {
    const data = ref({
      "id_evento": "",
      "id_pagamento_tipo": "",
      data: "",
      descricao: "",
      valor: "",
      comprovante: "",
      pix: "",
    });

    const mostraDados = ref();

    const dadosBancarios = ref({
      banco: "",
      agencia: "",
      "tipo_conta": "",
      "numero_conta": "",
      "numero_conta_digito": "",
      "nome_titular": "",
      "cpf_cnpj": "",
      "tipo_pessoa": "",

    });

    const loader = inject("loading");
    const alert = inject("alert");
    const route = useRoute();
    const router = useRouter();
    const today = ref(dataAtualFormatada("us"));
    const tipoPag = ref([]);
    const store = useStore();
    const tipoPessoa = ref([]);
    const tipoConta = ref([]);

    onMounted(async () => {
      data.value["id_evento"] = route.params.id_evento;

      const perm = await GET("pagamento_tipo");

      perm.forEach((p) => tipoPag.value.push({ value: p.id, label: p.tipo }));

      tipoPessoa.value = [{ label: "Jurídica", value: 'j' }, { label: "Física", value: 'f' }]
      tipoConta.value = [{ label: "Corrente", value: 1 }, { label: "Poupança", value: 2 }]


    });

    const create = async () => {
      loader.open();
      let object
      if (data.value.comprovante) {
        object = {
          ...data.value,
          file: data.value.comprovante
        };
      } else {
        object = { ...data.value };
      }
      const objbanco = { ...dadosBancarios.value }

      const aux = {
        ...data.value,
        pagamento: data.value.id_pagamento_tipo
      };

      delete aux.id_pagamento_tipo;
      delete object.comprovante
      delete aux.comprovante

      if (data.value.descricao == "" || data.value.descricao == null || data.value.descricao == undefined) {
        delete object.descricao
        delete aux.descricao
      }

      if (data.value.pix == "" || data.value.pix == null || data.value.pix == undefined) {
        delete object.pix
        delete aux.pix
      }

      const validate = isEmpty(aux);
      const validate2 = isEmpty(dadosBancarios.value);

      if (mostraDados.value) {

        if (validate || (validate2 && data.value.pix == "")) {
          if (validate) {
            alert.open("Atenção!", validate, "warning");
          } else {
            alert.open("Atenção!", validate2, "warning");
          }
        } else {

          const valorFloat = data.value.valor.split(" ", 2)
          valorFloat[1] = valorFloat[1].replace(".", "")
          object.valor = valorFloat[1].replace(",", ".");

          const objRequest = new FormData();
          const item = object;
          for (const key in item) {
            objRequest.append(key, item[key]);
          }

          try {
            objRequest.forEach((value, key) => {
              console.log(key, value)
            })
            const resposta = await POST("pagamento", objRequest);



            /* Aqui para baixo so quando informações do banco de pagamento funcionar */

            const idPag = resposta[0].generatedMaps[0].id



            objbanco["id_pagamento"] = idPag;

            if (!validate2) {

              const resposta2 = await POST("pagamento_dados_bancarios", objbanco);
            }

            alert.open("Sucesso!", `Pagamento criado com sucesso!`, "success");
            router.push(`/financeiro/${route.params.id_evento}`);

          } catch (e) {
            alert.open("Atenção!", e, "danger");
          }


        }

      } else {

        if (validate) {
          alert.open("Atenção!", validate, "warning");
        } else {

          const valorFloat = data.value.valor.split(" ", 2);
          valorFloat[1] = valorFloat[1].replace(".", "");
          object.valor = valorFloat[1].replace(",", ".");

          const objRequest = new FormData();
          const item = object;
          for (const key in item) {
            objRequest.append(key, item[key]);
          }

          try {

            await POST("pagamento", objRequest);

            alert.open("Sucesso!", `Pagamento criado com sucesso!`, "success");
            router.push(`/financeiro/${route.params.id_evento}`);

          } catch (e) {
            alert.open("Atenção!", e, "danger");
          }


        }

      }

      loader.close();
    };

    const readURL = (file) => {
      if (typeof file == "string") {
        return file;
      }
      return window.URL.createObjectURL(file);
    };

    const visualiarComprovante = (file) => {
      if (typeof file != "string") {

        const urlFile = window.URL.createObjectURL(file);

        window.open(urlFile)

      } else if (typeof file == "string") {
        fetch(file)
          .then(resp => resp.blob())
          .then(blob => {

            const url = window.URL.createObjectURL(blob);
            window.open(url)
          })
          .catch(() => alert.open("Error", "Não foi possivel recuperar o comprovante", "danger"));
      }
      else {
        alert.open("Atenção!", "Não existe comprovante para visualizar", "warning")
      }
    }


    const changeImage = (event) => {
      const obj = {
        "id_evento": data.value["id_evento"],
        "id_pagamento_tipo": data.value["id_pagamento_tipo"],
        data: data.value.data,
        descricao: data.value["descricao"],
        valor: data.value.valor,
        pix: data.value.pix,
        comprovante: event.target.files[0],
      }
      data.value = obj;
    }


    watch(() => data.value.id_pagamento_tipo, () => {
      if (data.value.id_pagamento_tipo == 1 || data.value.id_pagamento_tipo == 2 || data.value.id_pagamento_tipo == 8) {
        mostraDados.value = true
      } else {
        mostraDados.value = false
      }
    })

    return {
      data,
      create,
      today,
      tipoPag,
      dadosBancarios,
      store,
      changeImage,
      readURL,
      visualiarComprovante,
      tipoPessoa,
      tipoConta,
      mostraDados,
      route
    };
  },
};
</script>

<style>
.btn {
  width: 100%;
  align-items: center;
  justify-content: center;
}

.label {
  position: absolute;

  background-color: #CCCCCC;
}

.bton {
  z-index: 5;
  width: 280px;
  height: 75px;
}

input[type="file"]::-webkit-file-upload-button {
  height: 40px;
}
</style>